import axios from "axios";

export const fetchAppointments = (data) => {
  let arrStr = encodeURIComponent(JSON.stringify(data.dow));
  let patientPrefs = encodeURIComponent(JSON.stringify(data.patientPrefs));
  return axios.get(`${process.env.REACT_APP_FLEXAPP}/os/getschedule?c=${data.client}&appointmentId=${data.appointmentId}&providerId=${data.providerId}&dow=${arrStr}&patientPrefs=${patientPrefs}&month=${data.month}&year=${data.year}`);
};

export const fetchAllAppointments = (data) => {
  let arrStr = encodeURIComponent(JSON.stringify(data.dow));
  let patientPrefs = encodeURIComponent(JSON.stringify(data.patientPrefs));
  return axios.get(`${process.env.REACT_APP_FLEXAPP}/os/getAllSchedule?c=${data.client}&appointmentId=${data.appointmentId}&providerId=${data.providerId}&dow=${arrStr}&patientPrefs=${patientPrefs}&month=${data.month}&year=${data.year}`);
}

export const createAppointment = (data) => {
  let guarantor = null
  if (data.guarantor) {
    guarantor = {
      ...data.guarantor,
      phone: `1${data.guarantor.phone.replace(/[^\d]/g, '')}`
    }
  }

  const patientInfo = {
    email: data.email,
    fname: data.fname,
    lname: data.lname,
    phone: `1${data.phone}`,
    dob: data.dob,
    insurance: data.insurance,
    insurance_change: data.insuranceChange,
    language: data.language,
    returning: data.returning,
    subscriber_id: data.subscriber_id,
    tracking: data.tracking,
    qas: data.qas,
    token: data.token,
    expiry: data.expiry,
    lastfour: data.lastfour,
    guarantor
  }

  return axios.post(`${process.env.REACT_APP_FLEXAPP}/os/createAppointment?c=${data.client}&appointment_date=${data.appointment_date}&appointment_id=${data.appointment_id}&appointment_time=${data.appointment_time}&operatory=${data.operatory}&provider_id=${data.provider_id}`, patientInfo)
}

export const verifyAppointment = (data) => {
  return axios.get(`${process.env.REACT_APP_FLEXAPP}/os/sendVerification?phone=${data.phone}`);
}

export const checkVerification = (data) => {
  return axios.get(`${process.env.REACT_APP_FLEXAPP}/os/checkVerification?phone=${data.phone}`);
}
