import React from 'react';
import styled from 'styled-components';
import './appointments.scss';
import CSSFilter from '../../styles/css-filter';
import { hexToRgb } from '../../styles/css-filter';

import $ from "jquery";
import { getContrast } from '../../styles/contrast';

import FlexIcon from '../../styles/flex_icon';
import { withTranslation } from 'react-i18next';
import i18n from '../../../lib/i18n/index.js';


class Appointments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      hover: JSON.parse(window.sessionStorage.phoneType || true)
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.sessionStorage.removeItem("backSkip");
    const currentPage = window.sessionStorage.getItem("currentPage");

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.sessionStorage.setItem("phoneType", true);
      this.setState({ hover: false });
    } else {
      window.sessionStorage.setItem("phoneType", false);
      this.setState({ hover: true });
    }

    if (window.location !== window.parent.location) {
      if (parseInt(currentPage) === 1) {
        window.sessionStorage.setItem("currentPage", 2);
      } else {
        if (this.props.match.params.analytics !== undefined) {
          this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        } else {
          this.props.history.push(`/${this.props.match.params.id}/${parseInt(currentPage) !== 0 ? currentPage : ""}`);
        }
      }
    } else {
      if (parseInt(currentPage) === 1 || parseInt(currentPage) === 2) {
        if (window.securedNav === true && parseInt(currentPage) === 2) {
          window.sessionStorage.setItem("currentPage", 1);
        } else {
          if (parseInt(currentPage) === 1) {
            window.sessionStorage.setItem("currentPage", 1);
          } else {
            window.sessionStorage.setItem("currentPage", 2);
          }
        }
      } else {
        if (window.securedNav === true && parseInt(currentPage) === 3) {
          window.sessionStorage.setItem("currentPage", 2);
        } else {
          if (this.props.match.params.analytics !== undefined) {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/`);
            } else {
              this.props.history.replace(`/${this.props.match.params.id}/${this.props.match.params.analytics}/${parseInt(currentPage) - 1}`);
            }
          } else {
            if (parseInt(currentPage) === 0) {
              this.props.history.replace(`/${this.props.match.params.id}/`);
            } else {
              this.props.history.replace(`/${this.props.match.params.id}/${parseInt(currentPage) - 1}`);
            }
          }
        }
      }

      window.securedNav = false;
    }

    const backButton = document.getElementsByClassName("backButton");
    $(backButton).removeClass("hideButton");
  }

  handleClick(e) {
    e.preventDefault();

    window.sessionStorage.setItem("appointmentId", e.currentTarget.dataset.appointment_id);
    window.sessionStorage.setItem("currentPage", 2);

    if (this.props.match.params.analytics !== undefined) {
      this.props.history.push(`/${this.props.match.params.id}/${this.props.match.params.analytics}/3`);
    } else {
      this.props.history.push(`/${this.props.match.params.id}/3`);
    }
  }

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleClick(e);
    }
  };

  render() {
    const { t } = this.props;
    const currentLanguage = i18n.language;

    const contrastColor = this.props.config.color ? this.props.config.color : "#2699fb";

    if (this.props.config === 500) {
      return null;
    }

    let appointments = this.props.config.appointments;

    if (appointments != null && appointments.length > 0) {
      appointments = appointments.sort(function (a, b) {
        return (a.appointment_id - b.appointment_id);
      });
    }

    if (window.sessionStorage.getItem("patientType") === 'new') appointments = appointments.filter(appointment => parseInt(appointment.new_patients) === 1);
    else appointments = appointments.filter(appointment => parseInt(appointment.exist_patients) === 1);

    let contrast = getContrast(contrastColor);

    const rgb = hexToRgb(contrast);

    const color = new CSSFilter.Color(rgb[0], rgb[1], rgb[2]);
    const solver = new CSSFilter.Solver(color);
    const result = solver.solve();

    const Div = styled.div`
      border: 1px solid #D0D0D0;
      background-color: white !important;

      &:hover {
        background-color: ${this.state.hover ? this.props.config.color : "white"} !important;
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }

      &:focus {
        border-color: #2699fb !important;
        outline: none !important;
      }

      &:hover .appointmentType {
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }

      &:hover .appointmentTypeTime {
        color: ${this.state.hover ? getContrast(contrastColor) : "#707070"} !important;
      }


      &:hover .appointmentTypeImage {
        filter: ${this.state.hover ? result.filter : "#707070"} !important;
      }
    `;

    const returning = window.sessionStorage.getItem("patientType");
    let greeting = returning === "returning" ? t(`We're looking forward to seeing you!`) : t("We're looking forward to meeting you!")
    let apptErr;

    if (appointments == null || appointments.length === 0) {
      apptErr = <div>
        <div className="row justify-content-center">
          <div className="col col-sm-12 col-md-10 locationsDescription">
            {t("It looks like there are no appointments currently eligible for online booking. Just give us a call!")}
          </div>
        </div>

        <div className="locationsNumber">
          {this.props.config.practicePhone}
        </div>
      </div>;
    } else {
      apptErr = <div className="appointmentsDescription">
        {t("Please choose an appointment type.")}
      </div>;
    }

    return (
      <div className="appointmentRow justify-content-center">
        <div className="apptContainer col col-11">
          <div>
            <div className="appointmentsHeader">
              {greeting}
            </div>

            {apptErr}

            <div className="row justify-content-center">
              {appointments.map((appointment, index) => {
                return (
                  <Div tabIndex="0" onKeyPress={this._handleKeyPress} key={index} className="col-xs-12 col-md-5 appointmentContainer" data-appointment_id={appointment.appointment_id} onClick={this.handleClick}>
                    <div className="alignIems">
                      <FlexIcon class="appointmentTypeImage" icon={appointment.icon} />

                      <div className="appointmentType">
                        {
                          currentLanguage === 'es' && appointment.name_translated ? appointment.name_translated : appointment.name
                        }
                      </div>

                      <div className="appointmentTypeTime">
                        {appointment.length} {t("minutes")}
                      </div>
                    </div>
                  </Div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Appointments)
